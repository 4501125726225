// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.

@import 'variable';
@import 'mixins';

.comma-list {
  &:after {
    content: ', ';
  }
  &:last-child {
    &:after {
      content: '';
    }
  }
}

.no-padding {
  padding: 0;
}

/* TOASTR BUGFIX */
#toast-container > div {
  opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}
.toast-success {
  background-color: var(--partake-color-success) !important;
  color: var(--partake-color-success-contrast) !important;
}
.toast-error {
  background-color: var(--partake-color-danger) !important;
  color: var(--partake-color-danger-contrast) !important;
}
.toast-info {
  background-color: var(--partake-color-secondary) !important;
  color: var(--partake-color-secondary-contrast) !important;
}
.toast-warning {
  background-color: var(--partake-color-warning) !important;
  color: var(--partake-color-warning-contrast) !important;
}

/* RESERVATION CONTENT */

.reservation-content {
  .dropdown-menu {
    &.show {
      height: 250px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

/* BADGES */

.badge {
  &.badge-success {
    background-color: var(--partake-color-success) !important;
    color: var(--partake-color-success-contrast) !important;
  }
  &.badge-error {
    background-color: var(--partake-color-danger) !important;
    color: var(--partake-color-danger-contrast) !important;
  }
  &.badge-danger {
    background-color: var(--partake-color-danger) !important;
    color: var(--partake-color-danger-contrast) !important;
  }
  &.badge-primary {
    background-color: var(--partake-color-primary) !important;
    color: var(--partake-color-primary-contrast) !important;
  }
  &.badge-secondary {
    background-color: var(--partake-color-secondary) !important;
    color: var(--partake-color-secondary-contrast) !important;
  }
  &.badge-warning {
    background-color: var(--partake-color-warning) !important;
    color: var(--partake-color-warning-contrast) !important;
  }
  &.badge-tertiary {
    background-color: var(--partake-color-tertiary) !important;
    color: var(--partake-color-tertiary-contrast) !important;
  }
  &.badge-dark {
    background-color: var(--partake-color-dark) !important;
    color: var(--partake-color-dark-contrast) !important;
  }
  &.badge-medium {
    background-color: var(--partake-color-medium) !important;
    color: var(--partake-color-medium-contrast) !important;
  }
  &.badge-light {
    background-color: var(--partake-color-light) !important;
    color: var(--partake-color-light-contrast) !important;
  }

  &.active {
    background-color: var(--partake-color-success) !important;
    color: var(--partake-color-success-contrast) !important;
  }
  &.error {
    background-color: var(--partake-color-danger) !important;
    color: var(--partake-color-danger-contrast) !important;
  }
}

/* LOADING OVERLAY */
.loading {
  position: relative;

  &:after {
    @include overlay();
    @include fadeInAnimation();

    background-color: rgba($black, 0.03);
    content: '';
    pointer-events: none;
  }
}

table.loading {
  @extend .loading;

  &:after {
    top: calc(2rem + 24px); // thead line height
  }
}

/* TEXT UTILS */
.text-bold {
  font-weight: bold;
}

/* TABLE STYLES */
.table-responsive {
  min-height: 150px;
}

.modal-content {
  .table-responsive {
    min-height: auto;
  }
}

.table {
  .th-checkbox {
    padding-left: 8px;

    .input-group-text {
      padding: 10px 16px;
      height: 25px;

      .form-check-input {
        margin: 0;
        margin-left: -6px;
      }

      .form-check-label {
        margin-left: 15px;
      }
    }

    .input-group-append {
      height: 25px;
    }
  }
}

/* TABLE UTILS */
td.no-border,
th.no-border,
tr.no-border,
tr.no-border > td {
  border: none !important;
}

tr.border-top,
tr td.border-top,
tr th.border-top {
  border-top: 1px solid $gray-300 !important;
}

th.sortable {
  cursor: pointer;
  font-weight: bold !important;

  &.sort-arrow {
    display: inline-block;
    line-height: 0;
    margin-left: 2px;
    vertical-align: middle;
  }

  &.asc {
    &:after {
      @extend .sort-arrow;
      content: '\25B4';
      font-size: 0.8em;
      padding-bottom: 2px;
    }
  }

  &.desc {
    &:after {
      @extend .sort-arrow;
      content: '\25BE';
      font-size: 1em;
    }
  }
}

/* LIST UTILS */
ul.plain,
ol.plain {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* LINK UTILS */
.clickable {
  cursor: pointer;
}

/* TAGS INPUT */
tag-input.form-control {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 6px;

  .ng2-tag-input {
    border: none !important;
  }

  &.block .ng2-tag-input {
    width: 100%;
  }
}

/* DRAGULA CUSTOMIZATION FOR DRAG & DROP */

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

/* SWITCH BUTTON */
ui-switch button {
  outline: none !important;
}

/* NGX-FORM */

.widget {
  .control-label {
    width: 80%;
  }

  .formHelp {
    display: block;
  }
}

sf-array-widget {
  .widget {
    border-bottom: 1px solid var(--partake-color-light);
  }

  .array-add-button,
  .array-remove-button {
    min-width: calc(20% - 5px);
  }

  .array-remove-button {
    background-color: var(--partake-color-danger);
  }

  div + .array-add-button {
    position: absolute;
    right: 20px;
    margin-top: -35px;
    min-width: calc(20% - 13px);
  }
}

// CAROUSEL
.carousel {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: var(--partake-color-medium) !important;
  }
}

.select-location-modal {
  .modal-content {
    background-color: var(--partake-color-light);
  }
}

.loading-button {
  min-width: 100px;

  .spinner {
    height: 20px;
    width: 20px;
    margin: 0;
  }
}

.btn {
  border-radius: 3px;
}

.text-right {
  text-align: right;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-secondary {
  --bs-btn-color: var(--partake-color-secondary-contrast);
}

.btn-outline-secondary {
  --bs-btn-color: var(--partake-color-secondary);
  --bs-btn-hover-color: var(--partake-color-secondary-contrast);
  --bs-btn-hover-bg: var(--partake-color-secondary);
  --bs-btn-hover-border-color: var(--partake-color-secondary);
}

.c3-chart-arcs-title {
  fill: $text-muted;
}

.c3 line,
.c3 path {
  stroke: $gray-200;
}
.c3 text {
  fill: $text-muted;
}
.c3-chart-arc path {
  stroke: transparent;
}
