//
// Custom layout variables
//
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

:root {
  --partake-color-primary: #0b0d22;
  --partake-color-primary-rgb: 11, 13, 34;
  --partake-color-primary-contrast: #ffffff;
  --partake-color-primary-contrast-rgb: 255, 255, 255;
  --partake-color-primary-shade: #0a0b1e;
  --partake-color-primary-tint: #232538;

  --partake-color-secondary: #2c82c9;
  --partake-color-secondary-rgb: 44, 130, 201;
  --partake-color-secondary-contrast: #ffffff;
  --partake-color-secondary-contrast-rgb: 255, 255, 255;
  --partake-color-secondary-shade: #2772b1;
  --partake-color-secondary-tint: #418fce;

  --partake-color-tertiary: #f19f4d;
  --partake-color-tertiary-rgb: 241, 159, 77;
  --partake-color-tertiary-contrast: #ffffff;
  --partake-color-tertiary-contrast-rgb: 0, 0, 0;
  --partake-color-tertiary-shade: #d48c44;
  --partake-color-tertiary-tint: #f2a95f;

  --partake-color-success: #57b35f;
  --partake-color-success-rgb: 87, 179, 95;
  --partake-color-success-contrast: #ffffff;
  --partake-color-success-contrast-rgb: 0, 0, 0;
  --partake-color-success-shade: #4d9e54;
  --partake-color-success-tint: #68bb6f;

  --partake-color-warning: #fde3a7;
  --partake-color-warning-rgb: 253, 227, 167;
  --partake-color-warning-contrast: #000000;
  --partake-color-warning-contrast-rgb: 0, 0, 0;
  --partake-color-warning-shade: #dfc893;
  --partake-color-warning-tint: #fde6b0;

  --partake-color-danger: #96281b;
  --partake-color-danger-rgb: 150, 40, 27;
  --partake-color-danger-contrast: #ffffff;
  --partake-color-danger-contrast-rgb: 255, 255, 255;
  --partake-color-danger-shade: #842318;
  --partake-color-danger-tint: #a13e32;

  --partake-color-dark: #2c2c2c;
  --partake-color-dark-rgb: 44, 44, 44;
  --partake-color-dark-contrast: #ffffff;
  --partake-color-dark-contrast-rgb: 255, 255, 255;
  --partake-color-dark-shade: #272727;
  --partake-color-dark-tint: #414141;

  --partake-color-medium: #989aa2;
  --partake-color-medium-rgb: 152, 154, 162;
  --partake-color-medium-contrast: #ffffff;
  --partake-color-medium-contrast-rgb: 255, 255, 255;
  --partake-color-medium-shade: #86888f;
  --partake-color-medium-tint: #a2a4ab;

  --partake-color-light: #f4f5f8;
  --partake-color-light-rgb: 244, 244, 244;
  --partake-color-light-contrast: #000000;
  --partake-color-light-contrast-rgb: 0, 0, 0;
  --partake-color-light-shade: #d7d8da;
  --partake-color-light-tint: #f5f6f9;

  --partake-background: #fff;
}

/*Theme Colors*/

$themecolor: #0b0d22;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;

$skin1: #0b0d22;
$skin2: #fe5419;
$skin3: #00b0ff;
$skin4: #6659f7;
$skin5: #414755;
$skin6: #fff;

//
// Topbar settings
//

$topbar: $theme-light;
$topbar-height: 64px;
$topbar-navlink-padding: 0px 14px;
$topbar-navlink-font-size: 0.875rem;
$topbar-navlink-height: 64px;
$topbar-navbrand-padding: 0 10px 0 10px;

//
// Sidebar settings
//

$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #212529;
$sidebar-icons-dark: #4f5467;

$sidebar-width-full: 250px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 65px;

//
// Layout settings
//
$boxed-width: 1200px;
$page-wrapper-max-width: 1300px;

//
// Common settings
//
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.05);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;

//
// Mixin settings
//
%square {
  border-radius: 0px;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

//
// Bootstrap override colors
//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #a1aab2 !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2c82c9 !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #96281b !default;
$orange: #f19f4d !default;
$yellow: #fde3a7 !default;
$green: #57b35f !default;
$light-green: #bbe5b3 !default;
$teal: #20c997 !default;
$cyan: #4fc3f7 !default;
$primary: #0b0d22 !default;
$secondary: #2c82c9 !default;
$dark: #2c2c2c !default;
$medium: #989aa2 !default;
$light: #f4f5f8 !default;
$text-muted: $gray-500 !default;

$light-primary: #e1f0ff !default;
$light-info: #d8e2ff !default;
$light-warning: #fbeccd !default;
$light-danger: #ffdfe2 !default;
$light-success: #bcf5f2 !default;
$light-secondary: #e4eaef !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);

$primary: $primary !default;
$secondary: $secondary !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $light !default;
$dark: $dark !default;
$medium: $medium !default;
$muted: $gray-500 !default;
$cyan: $cyan !default;
$orange: $orange !default;
$theme-colors: () !default;

$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'muted': $muted,
    'cyan': $cyan,
    'orange': $orange,
    'purple': $purple,
    'light-primary': $light-primary,
    'light-info': $light-info,
    'light-warning': $light-warning,
    'light-danger': $light-danger,
    'light-success': $light-success,
    'light-secondary': $light-secondary,
  ),
  $theme-colors
);

//
// Dark Theme Colors Settings
//
$theme-dark-bg-layout: #323743;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: rgba(255, 255, 255, 0.4) !default;
$theme-dark-card-bg: #272b34;
$theme-dark-bg-light: #3c424e;
$main-body-bg-dark: $gray-900 !default;
$background-alt: #2b2b2b !default;

//
// Enable optional
//
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
$enable-negative-margins: true;

//
// Body
//
$main-body-bg: #fff !default;
$body-bg: #eef5f9;
$body-color: #3e5569 !default;
$grid-gutter-width: 20px !default;

//
// Typography
//
$font-size-base: 0.875rem;
$font-family-sans-serif: 'Nunito Sans', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 600 !default;
$fw-bold: 800 !default;
$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;
$headings-margin-bottom: 8px !default;
$headings-font-weight: 700 !default;
$headings-color: inherit !default;

// Font size
$font-sizes: (
  1: 10px,
  2: 12px,
  3: 14px,
  4: 16px,
  5: 18px,
  6: 20px,
  7: 24px,
);

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2rem,
) !default;

//
// Breadcrumb
//
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

//
// Cards
//
$card-border-width: 0px !default;
$card-border-color: transparent !default;
$card-border-radius: 4px !default;

//
// Dropdowns
//
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200 !default;
$dropdown-divider-bg: $gray-100 !default;

//
// Buttons
//
$btn-secondary-border: $gray-300;
$btn-border-radius: 4px !default;
$btn-border-radius-sm: 4px !default;
$btn-border-radius-lg: 4px !default;
$btn-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$input-btn-focus-width: 0 !default;
$input-btn-focus-color-opacity: 0 !default;
$input-btn-focus-color: rgba(transparent, $input-btn-focus-color-opacity) !default;
$btn-close-focus-shadow: none !default;

//
// Progressbar
//
$progress-bg: $gray-100;

//
// Tables
//
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;
$table-border-color: $gray-200;
$table-group-separator-color: transparent;
$table-striped-bg: $light !default;
$table-cell-padding-y-sm: 10px !default;
$table-cell-padding-x-sm: 15px !default;

//
// Components
//
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;
$badge-font-size: 0.75rem !default;
$badge-font-weight: 400 !default;

//
// Forms
//
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: rgba(0, 0, 0, 0.25) !default;
$input-btn-focus-box-shadow: transparent !default;
$form-select-bg: transparent !default;
$form-select-focus-box-shadow: none !default;
$input-bg: none !default;
$input-focus-box-shadow: none !default;
$custom-select-focus-box-shadow: transparent !default;
// $custom-select-indicator: url(../../assets/images/custom-select.png) !default;
$border-width: 1px !default;
$border-color: $gray-200 !default;
$border-radius: 4px !default;
$border-radius-lg: 5px !default;
$border-radius-sm: 1px !default;

//
// Checkbox
//
$form-check-input-bg: #ebedf3 !default;
$form-check-input-border: 0 !default;
$form-check-input-border-radius: 4px !default;
$form-check-input-width: 1.375em !default;
$form-check-input-height: 1.375em !default;
$form-check-input-focus-box-shadow: none !default;
$form-range-track-bg: $gray-300 !default;

//
// Progressbars
//
$progress-height: 5px !default;

//
// Tabs
//
$nav-tabs-link-active-bg: $white !default;

//
// Grid breakpoints
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1600px,
) !default;

//
// Navbar
//
$navbar-dark-color: rgba($white, 0.8) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;

//
// accordian
//
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1rem !default;
$accordion-bg: transparent !default;
$accordion-button-active-color: $info !default;

//
// alert
//
$alert-bg-scale: 0% !default;
$alert-border-scale: 0% !default;
$alert-color-scale: 1% !default;
