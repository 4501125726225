.ngb-dp-weekday {
    color: $body-color !important;
    font-size: 12px;
    font-weight: 600;
    font-style: normal !important;
}

.ngb-dp-header,
.ngb-dp-weekdays,
.ngb-dp-month-name {
    background-color: transparent !important;
}

ngb-datepicker-navigation-select>.form-select:first-child {
    margin-right: 7px;
}

.btn-link.ngb-dp-arrow-btn {
    color: $text-muted;
    &:hover {
        color: $primary;
    }
}

.ngb-dp-month-name {
    margin-top: 7px;
}

.ngb-dp-day .btn-light {
    color: $body-color;
    &:hover {
        background-color: $light-primary;
    }
}

