/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

//(fonts)
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

//(Required file)
@import 'variable';
@import 'css-variable';

// Import Bootstrap source files (Required file)
@import '../../../node_modules/bootstrap/scss/bootstrap';

//This is for the components (Required file)
@import 'components';

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import 'pages/pages';

//This is for the widgets (Required file)
@import 'widgets/widgets';

//This is for the responsive (Required file)
@import 'responsive';

//In This scss you can write your scss
@import 'animations';
@import 'custom';

@import 'plugins/plugins';

//This is for the icons (Required file)
@import 'icons/simple-line-icons/css/simple-line-icons.css';
@import 'icons/weather-icons/sass/weather-icons';
@import 'icons/themify-icons/themify-icons.css';
@import 'icons/flag-icon-css/flag-icon.min.css';
@import 'icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'icons/crypto-icons/cryptocoins.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
