.todo-widget {
    .todo-list {
        .todo-item {
            padding: 20px 15px 15px 0px;
            .assignedto {
                padding: 0 0 0 25px;
                margin: 0px;
                .assignee {
                    .assignee-img {
                        width: 30px;
                    }
                }
            }
            .item-date {
                padding-left: 25px;
            }
        }
    }
}

.list-task .task-done {
    .todo-desc,
    .badge {
        text-decoration: line-through;
    }
}

.app-sidebar {
    right: 0;
    position: fixed;
    width: 0;
    top: 64px;
    height: 100%;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in;
    z-index: 99;
}