//
// Chartist Chart
//
html {
  .ct-area,
  .ct-line {
    pointer-events: none;
  }

  .ct-series-a .ct-area {
    fill: $cyan;
  }

  .ct-series-b .ct-area {
    fill: $info;
  }

  .ct-series-c .ct-area {
    fill: $indigo;
  }

  .ct-series-c .ct-area {
    fill: $success;
  }

  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: $cyan;
  }

  .ct-series-b .ct-point,
  .ct-series-b .ct-line,
  .ct-series-b .ct-bar,
  .ct-series-b .ct-slice-donut {
    stroke: $info;
  }

  .ct-series-c .ct-point,
  .ct-series-c .ct-line,
  .ct-series-c .ct-bar,
  .ct-series-c .ct-slice-donut {
    stroke: $indigo;
  }

  .ct-series-d .ct-point,
  .ct-series-d .ct-line,
  .ct-series-d .ct-bar,
  .ct-series-d .ct-slice-donut {
    stroke: $success;
  }
}

//
// Apexchart
//
.apexcharts-text {
  fill: $text-muted;
}
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line {
  stroke: rgba(0, 0, 0, 0.1);
}
