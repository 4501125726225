/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
  padding: 20px 20px 0 20px;
  max-width: $page-wrapper-max-width;
  margin-left: auto;
  margin-right: auto;
  .page-title {
    margin-bottom: 0px;
  }
  .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    font-size: 12px;
    .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $gray-400;
      font-size: 11px;
    }
  }
}

// breadcrumb
.breadcrumb-item {
  list-style: none;
  padding-right: 10px;
}
.breadcrumb-item + .breadcrumb-item::before {
  margin-right: 10px;
  content: "\e649";
  font-family: themify;
  color: $gray-600;
  font-size: 11px;
  margin-top: 4px;
}
