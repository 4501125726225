/** CSS Variables **/
:root {
  --partake-color-primary: #0b0d22;
  --partake-color-primary-rgb: 11, 13, 34;
  --partake-color-primary-contrast: #ffffff;
  --partake-color-primary-contrast-rgb: 255, 255, 255;
  --partake-color-primary-shade: #0a0b1e;
  --partake-color-primary-tint: #232538;

  --partake-color-secondary: #2c82c9;
  --partake-color-secondary-rgb: 44, 130, 201;
  --partake-color-secondary-contrast: #ffffff;
  --partake-color-secondary-contrast-rgb: 255, 255, 255;
  --partake-color-secondary-shade: #2772b1;
  --partake-color-secondary-tint: #418fce;

  --partake-color-tertiary: #f19f4d;
  --partake-color-tertiary-rgb: 241, 159, 77;
  --partake-color-tertiary-contrast: #ffffff;
  --partake-color-tertiary-contrast-rgb: 0, 0, 0;
  --partake-color-tertiary-shade: #d48c44;
  --partake-color-tertiary-tint: #f2a95f;

  --partake-color-success: #57b35f;
  --partake-color-success-rgb: 87, 179, 95;
  --partake-color-success-contrast: #ffffff;
  --partake-color-success-contrast-rgb: 0, 0, 0;
  --partake-color-success-shade: #4d9e54;
  --partake-color-success-tint: #68bb6f;

  --partake-color-warning: #fde3a7;
  --partake-color-warning-rgb: 253, 227, 167;
  --partake-color-warning-contrast: #000000;
  --partake-color-warning-contrast-rgb: 0, 0, 0;
  --partake-color-warning-shade: #dfc893;
  --partake-color-warning-tint: #fde6b0;

  --partake-color-danger: #96281b;
  --partake-color-danger-rgb: 150, 40, 27;
  --partake-color-danger-contrast: #ffffff;
  --partake-color-danger-contrast-rgb: 255, 255, 255;
  --partake-color-danger-shade: #842318;
  --partake-color-danger-tint: #a13e32;

  --partake-color-dark: #2c2c2c;
  --partake-color-dark-rgb: 44, 44, 44;
  --partake-color-dark-contrast: #ffffff;
  --partake-color-dark-contrast-rgb: 255, 255, 255;
  --partake-color-dark-shade: #272727;
  --partake-color-dark-tint: #414141;

  --partake-color-medium: #989aa2;
  --partake-color-medium-rgb: 152, 154, 162;
  --partake-color-medium-contrast: #ffffff;
  --partake-color-medium-contrast-rgb: 255, 255, 255;
  --partake-color-medium-shade: #86888f;
  --partake-color-medium-tint: #a2a4ab;

  --partake-color-light: #f4f5f8;
  --partake-color-light-rgb: 244, 244, 244;
  --partake-color-light-contrast: #000000;
  --partake-color-light-contrast-rgb: 0, 0, 0;
  --partake-color-light-shade: #d7d8da;
  --partake-color-light-tint: #f5f6f9;

  --partake-background: #fff;
}
